import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useCityStore } from 'src/store';

import { colors, scale } from '@scripts/gds';

import LocationIcon from '@icons/20/location.svg';

const CityPopup = dynamic(() => import('@components/CityPopup'), { ssr: false });

const CityBlock = () => {
    const { pathname } = useRouter();

    const currentCity = useCityStore(({ currentCity }) => currentCity);

    const [isCityPopup, setIsCityPopup] = useState(false);

    return (
        <>
            {pathname === '/' && (
                <div
                    css={{
                        backgroundColor: '#2A3641',
                        borderRadius: scale(1, false, 6),
                        padding: `${scale(1, false, 10)}px ${scale(1)}px`,
                        position: 'relative',
                        marginTop: scale(1),
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: scale(1),
                    }}
                >
                    <div css={{ display: 'flex', alignItems: 'center', minWidth: 'fit-content' }}>
                        <LocationIcon css={{ marginRight: scale(1, false, 10) }} />
                        <span css={{ color: colors.textNuanceLight, marginRight: scale(1, true) }}>Ваш город:</span>

                        <button css={{ color: colors.secondaryOnDark }} onClick={() => setIsCityPopup(true)}>
                            {currentCity.city}
                        </button>
                    </div>
                </div>
            )}

            <CityPopup isOpen={isCityPopup} closeHandler={() => setIsCityPopup(false)} />
        </>
    );
};

export default CityBlock;
