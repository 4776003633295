import { CSSObject } from '@emotion/core';
import { FormikValues } from 'formik';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useVerificationCodeByEmail } from '@api/auth';
import { useRegisterCustomerByCode } from '@api/customers';

import Form from '@controls/Form';

import { CodeInput } from '@components/CodeInput';
import Loader from '@components/controls/Loader';

import { Button, Layout, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

export interface SendCodeProps {
    logIn: (params: { email: string; code: string }) => void;
    closeHandler: () => void;
    backToSignin: () => void;
    phone?: string;
    email?: string;
    errorMessage?: string;
    registerInfo?: any;
    setErrorMessage: (arg: string) => void;
    isLogin?: boolean;
    path?: string;
    setEmail: (arg: string) => void;
}

const SendCode = ({
    closeHandler,
    logIn,
    backToSignin,
    email,
    errorMessage,
    registerInfo,
    isLogin,
    setErrorMessage,
    path,
    setEmail,
}: SendCodeProps) => {
    const { xs } = useMedia();

    const errorStyles: CSSObject = {
        background: colors.backgroundRed,
        borderRadius: scale(1, true),
        fontSize: '14px',
        padding: `${scale(1)}px ${scale(2)}px`,
        color: colors.actionRed,
        margin: `8px 0`,

        [xs]: {
            margin: `${scale(2)}px 0 0`,
        },
    };

    const { push } = useRouter();
    const {
        mutateAsync: verificationCodeByEmail,
        isLoading: isLoadingVerificationCodeByEmail,
        error,
    } = useVerificationCodeByEmail();

    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [canSendAgain, setCanSendAgain] = useState(false);

    const TIMER_DURATION = 60;

    const startTimer = () => {
        setTimeLeft(TIMER_DURATION);

        const timer = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);

            setCanSendAgain(true);
        }, 1000 * TIMER_DURATION);
    };

    const {
        mutateAsync: onRegisterCustomer,
        isLoading: isRegisterCustomerLoading,
        error: errorRegisterCustomer,
    } = useRegisterCustomerByCode();

    const loginByEmail = async () => {
        await logIn({ email: email || '', code: code });
    };

    const register = async () => {
        await onRegisterCustomer({ ...registerInfo, type: 2, code: code, timezone: 'Europe/Moscow' });
    };

    const handlerSubmit = async () => {
        await verificationCodeByEmail({ email: email || '' });
        setCanSendAgain(false);
        setCode('');
        startTimer();
    };

    // reset back errors
    useEffect(() => {
        if (email) {
            startTimer();
        }

        return () => {
            setErrorMessage('');
        };
    }, [email]);

    useEffect(() => {
        if (code && code.length === 4 && isLogin) {
            setLoading(prev => !prev);
            loginByEmail()
                .catch(error => {
                    setErrorMessage(error.message);
                })
                .finally(() => setLoading(false));
        }
        if (code && code.length === 4 && registerInfo && registerInfo.phone && !isLogin) {
            setLoading(prev => !prev);
            register()
                .then(() => {
                    if (path) {
                        push(path);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(error.message);
                });
        }
    }, [code]);

    return (
        <Form onSubmit={handlerSubmit} initialValues={{ code: '' }}>
            {({ submitForm }): FormikValues => (
                <Layout cols={1} gap={0} css={{ alignItems: 'center', textAlign: 'center' }}>
                    {!path && (
                        <div
                            css={{
                                [xs]: {
                                    margin: '0 auto',
                                    backgroundColor: '#C9D1D6',
                                    height: '5px',
                                    width: '73px',
                                    borderRadius: '3px',
                                    marginBottom: '20px',
                                },
                            }}
                        />
                    )}
                    <Layout.Item css={{ marginBottom: '24px' }}>
                        <h2
                            css={{
                                fontSize: '28px',
                                fontWeight: 500,
                                lineHeight: '33.6px',
                                textAlign: 'center',
                            }}
                        >
                            Введите код
                        </h2>
                    </Layout.Item>

                    <Layout.Item css={{ marginBottom: '24px' }}>
                        <div>
                            Мы отправили код на почту <span css={{ fontWeight: 500 }}>{email}</span>
                            {'  '}
                            <span
                                onClick={() => {
                                    backToSignin();
                                    setErrorMessage('');
                                    setEmail('');
                                }}
                                css={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    lineHeight: '22.4px',
                                    color: '#2DB4B5',
                                    marginLeft: '12px',
                                    [xs]: {
                                        display: 'block',
                                    },
                                }}
                            >
                                Указать другую
                            </span>
                        </div>
                    </Layout.Item>
                    <Layout.Item css={{ margin: '0 auto', marginBottom: errorMessage ? '0px' : '24px' }}>
                        {isLoading ? (
                            <Loader css={{ position: 'static', padding: '0' }} width={64} height={64} />
                        ) : (
                            <CodeInput onChange={setCode} />
                        )}
                    </Layout.Item>
                    {errorMessage && (
                        <div css={{ ...errorStyles, marginBottom: '24px' }}>
                            {errorMessage || errorRegisterCustomer?.message}
                        </div>
                    )}

                    {!canSendAgain && (
                        <Layout.Item
                            css={{
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '44px',
                            }}
                        >
                            <div>Если код не придёт, можно получить новый через {timeLeft} сек.</div>
                        </Layout.Item>
                    )}

                    {!canSendAgain && false && (
                        <Layout.Item
                            css={{
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '44px',
                            }}
                        >
                            <div>Вы превысили число попыток отправки кода. Попробуйте через 30 мин.</div>
                        </Layout.Item>
                    )}

                    {canSendAgain && (
                        <Layout.Item css={{ marginBottom: errorMessage ? '0px' : '44px' }}>
                            <Button
                                type="submit"
                                block
                                theme="secondary"
                                size="md"
                                data-testid="button-submit"
                                role="button"
                                css={{ marginTop: '24px' }}
                            >
                                Получить код
                            </Button>
                        </Layout.Item>
                    )}
                </Layout>
            )}
        </Form>
    );
};

export default SendCode;
