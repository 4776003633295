import dynamic from 'next/dynamic';
import { FC, useCallback, useEffect, useState } from 'react';
import { useCityStore } from 'src/store';

import { useLoginHelper } from '@api/auth/helpers';

import { useCartContext } from '@context/cart';

import SendCodeByEmail from '@components/NewAuth/SendCodeByEmail';
import SendCodeByPhone from '@components/NewAuth/SendCodeByPhone';
import { SignIn } from '@components/NewAuth/SignIn';
import Popup from '@components/controls/Popup';

import { Button, colors, scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

const RegisterForm = dynamic(() => import('./RegisterForm'), { ssr: false });
const RestorePasswordForm = dynamic(() => import('./RestorePasswordForm'), { ssr: false });

interface AuthTitleProps {
    registration?: boolean;
    toggleForm: () => void;
}

const AuthTitle: FC<AuthTitleProps> = ({ registration, toggleForm }) => {
    const { xs } = useMedia();

    return (
        <>
            <div
                css={{
                    [xs]: {
                        margin: '0 auto',
                        backgroundColor: '#C9D1D6',
                        height: '5px',
                        width: '73px',
                        borderRadius: '3px',
                        marginBottom: '20px',
                    },
                }}
            />
            <h1
                css={{
                    fontsize: '28px',
                    fontWeight: 500,
                    lineHeight: '33.6px',
                    color: '#4A5661',
                    marginTop: 0,
                    [xs]: {
                        fontSize: '24px',
                        lineHeight: '28.8px',
                    },
                }}
            >
                {registration ? 'Регистрация' : 'Вход'}
                <Button
                    theme="ghost"
                    css={{ padding: '0 !important' }}
                    onClick={toggleForm}
                    data-testid="button-registration"
                    role="button"
                >
                    <span
                        css={{
                            ...typography('inputFields'),
                            color: colors.secondary,
                            marginLeft: scale(3),
                            cursor: 'pointer',
                        }}
                    >
                        {registration ? 'Вход' : 'Регистрация'}
                    </span>
                </Button>
            </h1>
        </>
    );
};

interface AuthPopupProps {
    isOpen: boolean;
    closeHandler: () => void;
    isRegistration?: boolean;
}

const AuthPopup: FC<AuthPopupProps> = ({ isOpen, closeHandler, isRegistration = false }) => {
    const { xs } = useMedia();
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [registerInfo, setRegisterInfo] = useState();
    const [isSignIn, setIsSignIn] = useState<boolean>(!isRegistration);
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [isSendCodeByPhone, setIsSendCodeByPhone] = useState<boolean>(false);
    const [isSendCodeByEmail, setIsSendCodeByEmail] = useState<boolean>(false);
    const [isRegistrationForm, setIsRegistrationForm] = useState<boolean>(isRegistration);
    const [isRestorePasswordForm, setIsRestorePasswordForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const { basketProducts } = useCartContext();

    const { loginCodeByPhoneHelper, loginCodeByEmailHelper, registerByCodeHelper } = useLoginHelper();

    const logInByPhone = useCallback(
        async logInData => {
            try {
                await loginCodeByPhoneHelper(logInData, setIsLoading, currentCity.region_guid, basketProducts);

                closeHandler();
                setIsSignIn(true);
                setIsRegistrationForm(false);
                setIsRestorePasswordForm(false);
                setIsSendCodeByPhone(false);
                setIsSendCodeByEmail(false);
                setErrorMessage('');
            } catch (error: any) {
                setErrorMessage(error.message);
            }
        },
        [currentCity.region_guid, basketProducts]
    );

    const logInByEmail = useCallback(
        async logInData => {
            try {
                await loginCodeByEmailHelper(logInData, setIsLoading, currentCity.region_guid, basketProducts);

                closeHandler();
                setIsSignIn(true);
                setIsRegistrationForm(false);
                setIsRestorePasswordForm(false);
                setIsSendCodeByPhone(false);
                setIsSendCodeByEmail(false);
                setErrorMessage('');
            } catch (error: any) {
                setErrorMessage(error.message);
            }
        },
        [currentCity.region_guid, basketProducts]
    );

    const registerByPhone = useCallback(
        async logInData => {
            try {
                await registerByCodeHelper(logInData, setIsLoading, currentCity.region_guid, basketProducts);

                closeHandler();
                setIsSignIn(true);
                setIsRegistrationForm(false);
                setIsRestorePasswordForm(false);
                setIsSendCodeByPhone(false);
                setIsSendCodeByEmail(false);
                setErrorMessage('');
            } catch (error: any) {
                setErrorMessage(error.message);
            }
        },
        [currentCity.region_guid, basketProducts]
    );

    useEffect(() => {
        return () => {
            setIsSignIn(true);
            setIsSendCodeByEmail(false);
            setIsSendCodeByPhone(false);
            setIsRestorePasswordForm(false);
            setIsRegistrationForm(false);
        };
    }, []);

    return (
        <Popup
            isOpen={isOpen}
            onRequestClose={() => {
                closeHandler();
                setIsSignIn(!isRegistration);
                setIsRegistrationForm(isRegistration);
                setIsRestorePasswordForm(false);
                setIsSendCodeByPhone(false);
                setIsSendCodeByEmail(false);
                setErrorMessage('');
            }}
            isAuth
            isSwipeable
            scrollInside
            isHighLevel
            closebuttonsize={11}
            borderWpaper={'popup-border-wrapper'}
            css={{ width: '100vw', maxWidth: '500px' }}
        >
            <Popup.Body
                css={{
                    padding: '32px',

                    [xs]: {
                        padding: '20px 16px 0 16px',
                    },
                }}
            >
                <div>
                    {isRegistrationForm && (
                        <>
                            <AuthTitle
                                registration
                                toggleForm={() => {
                                    setIsRegistrationForm(false);
                                    setIsSignIn(true);
                                }}
                            />
                            <RegisterForm
                                setIsLogin={setIsLogin}
                                onChangePhoneToLogin={() => {
                                    setIsSignIn(false);
                                    setIsRegistrationForm(false);
                                    setIsRestorePasswordForm(false);
                                    setIsSendCodeByPhone(true);
                                }}
                                onChangeEmailToLogin={() => {
                                    setIsSignIn(false);
                                    setIsRegistrationForm(false);
                                    setIsRestorePasswordForm(false);
                                    setIsSendCodeByEmail(true);
                                }}
                                setRegisterInfo={setRegisterInfo}
                                setPhone={setPhone}
                                setEmail={setEmail}
                                logIn={logInByPhone}
                                closeHandler={closeHandler}
                                toggleForm={() => {
                                    setIsRegistrationForm(false);
                                    setIsSignIn(false);
                                    setIsRestorePasswordForm(false);
                                    setIsSendCodeByPhone(true);
                                    setIsSendCodeByEmail(false);
                                }}
                            />
                        </>
                    )}

                    {isSignIn && (
                        <>
                            <AuthTitle
                                toggleForm={() => {
                                    setIsSignIn(false);
                                    setIsRegistrationForm(true);
                                }}
                            />
                            <SignIn
                                setIsLogin={setIsLogin}
                                onChangeEmailToLogin={() => {
                                    setIsSignIn(false);
                                    setIsRestorePasswordForm(false);
                                    setIsSendCodeByEmail(true);
                                }}
                                onChangePhoneToLogin={() => {
                                    setIsSignIn(false);
                                    setIsRestorePasswordForm(false);
                                    setIsSendCodeByPhone(true);
                                }}
                                setEmail={setEmail}
                                setPhone={setPhone}
                                onChangeToRegistration={() => {
                                    setIsSignIn(false);
                                    setIsRestorePasswordForm(false);
                                    setIsRegistrationForm(true);
                                }}
                                logIn={logInByPhone}
                                closeHandler={closeHandler}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        </>
                    )}

                    {isRestorePasswordForm && (
                        <RestorePasswordForm
                            closeHandler={() => {
                                closeHandler();
                                setIsSignIn(false);
                                setIsRestorePasswordForm(false);
                            }}
                            backToSignin={() => {
                                setIsSignIn(true);
                                setIsRestorePasswordForm(false);
                            }}
                        />
                    )}

                    {isSendCodeByPhone && (
                        <SendCodeByPhone
                            setPhone={setPhone}
                            register={registerByPhone}
                            email={email}
                            isLogin={isLogin}
                            registerInfo={registerInfo}
                            logIn={logInByPhone}
                            phone={phone}
                            closeHandler={() => {
                                closeHandler();
                                setIsSignIn(true);
                                setIsRestorePasswordForm(false);
                                setIsSendCodeByEmail(false);
                                setIsSendCodeByPhone(false);
                            }}
                            backToSignin={() => {
                                setIsSignIn(true);
                                setIsSendCodeByPhone(false);
                            }}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                        />
                    )}
                    {isSendCodeByEmail && (
                        <SendCodeByEmail
                            isLogin={isLogin}
                            registerInfo={registerInfo}
                            logIn={logInByEmail}
                            email={email}
                            setEmail={setEmail}
                            closeHandler={() => {
                                closeHandler();
                                setIsSignIn(true);
                                setIsRestorePasswordForm(false);
                                setIsSendCodeByEmail(false);
                                setIsSendCodeByPhone(false);
                            }}
                            backToSignin={() => {
                                setIsSignIn(true);
                                setIsSendCodeByEmail(false);
                            }}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                        />
                    )}
                </div>
            </Popup.Body>
        </Popup>
    );
};

export default AuthPopup;
