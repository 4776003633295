import { ComponentsTheme, scale } from '@greensight/gds';

import tokens from '../../../public/tokens.json';

const { colors } = tokens;

export const Button: ComponentsTheme['Button'] = {
    base: {
        default: {
            borderRadius: 6,
            half: false,
        },
    },
    sizes: {
        sm: {
            height: scale(3),
            padding: scale(1),
            iconOffset: scale(1, true),
            iconSize: scale(2),
            typography: 'button',
        },
        smBold: {
            height: scale(4),
            padding: scale(3, true),
            iconOffset: scale(1),
            iconSize: scale(3),
            typography: 'buttonSmBold',
        },
        md: {
            height: scale(6),
            padding: scale(3, true),
            iconOffset: scale(1),
            iconSize: scale(3),
            typography: 'buttonBold',
        },
        mdReg: {
            height: scale(6),
            padding: scale(3, true),
            iconOffset: scale(1),
            iconSize: scale(3),
            typography: 'buttonReg',
        },
        custom: {
            height: scale(2),
            iconOffset: scale(3, true),
            padding: 0,
            typography: 'button',
        },
        navigation: {
            height: scale(2),
            padding: 0,
            typography: 'button',
        },
        sber: {
            height: scale(5),
            padding: scale(3, true),
            iconOffset: scale(1),
            iconSize: scale(3),
            typography: 'button',
        },
    },
    themes: {
        primary: {
            default: {
                bg: colors.primary,
                color: colors.white,
            },
            hover: {
                bg: colors.primaryHover,
            },
            disabled: {
                bg: colors.grey200,
                color: colors.grey800,
            },
        },
        secondary: {
            default: {
                bg: colors?.secondary,
                color: colors?.white,
            },
            hover: {
                bg: colors?.primary,
            },
            disabled: {
                bg: colors.borderMain,
                color: colors.textLight,
            },
        },
        outlineGrey: {
            default: {
                bg: colors?.white,
                color: colors.textSecondGrey,
                border: colors.borderMain,
            },
            disabled: {
                border: colors.grey300,
                color: colors.grey800,
            },
        },
        outlineGreyAndPrimary: {
            default: {
                bg: colors?.white,
                color: colors.textMainBlack,
                border: colors.borderMain,
            },
            disabled: {
                border: colors.grey300,
                color: colors.grey800,
            },
            hover: {
                bg: colors?.primary,
                color: colors.white,
            },
        },
        offerPrimary: {
            default: {
                bg: colors?.white,
                color: colors.primary,
                border: 'transparent',
            },
            hover: {
                bg: colors?.grey200,
                color: colors.secondary,
            },
        },
        outlineSecondaryGrey: {
            default: {
                bg: colors?.white,
                color: colors.secondary,
                border: colors.grey300,
            },
            hover: {
                bg: colors?.grey300,
            },
            disabled: {
                border: colors.grey300,
                color: colors.grey800,
            },
        },
        outlineSecondary: {
            default: {
                bg: colors?.white,
                color: colors.secondary,
                border: colors.secondary,
            },
            hover: {
                bg: colors?.grey300,
            },
        },
        checkoutSubmit: {
            default: {
                bg: colors.secondary,
                color: colors.white,
            },
            hover: {
                bg: colors.sberHover,
            },
            disabled: {
                bg: colors.borderMain,
                color: colors.textLight,
            },
        },
        dashedSecondary: {
            default: {
                bg: colors?.white,
                color: colors.secondary,
                border: colors.borderMain,
            },
            hover: {
                bg: colors?.grey300,
            },
        },
        fill: {
            default: {
                bg: colors?.white,
                color: colors.grey900,
            },
            hover: {
                bg: colors.primary,
                color: colors.white,
            },
            disabled: {
                bg: colors.grey200,
                color: colors.grey800,
            },
        },
        greyLinks: {
            default: { bg: 'transparent', color: colors.textMainBlack, border: colors.backgroundMain, borderRadius: 0 },
            hover: {
                bg: colors.grey200,
            },
        },
        redLinks: {
            default: { bg: 'transparent', color: colors.actionRed, border: colors.backgroundMain, borderRadius: 0 },
            hover: {
                bg: colors.grey200,
            },
        },
        cityTheme: {
            default: {
                borderRadius: 0,
                bg: '#2A3641',
                color: colors.secondaryOnDark,
            },
        },
        fillGrey: {
            default: {
                bg: '#E2E9EE',
                color: colors.grey900,
                borderRadius: scale(9, true),
            },
        },
        fillDarkGrey: {
            default: {
                bg: colors.backgroundMain,
                color: colors.textMainBlack,
                borderRadius: 0,
            },
        },
        fillLightGrey: {
            default: {
                bg: colors.backgroundMain,
                color: colors.textMainBlack,
                border: colors.borderMain,
            },
        },
        ghost: {
            default: {
                bg: 'transparent',
                color: colors.grey900,
            },
            disabled: {
                bg: colors.grey200,
                color: colors.grey800,
            },
        },
        ghostSecondary: {
            default: {
                bg: 'transparent',
                color: colors.secondary,
            },
            disabled: {
                bg: colors.grey200,
                color: colors.grey800,
            },
        },
        gray: {
            default: {
                bg: 'transparent',
                color: colors.textNuanceLight,
            },
        },
        transparent: {
            default: {
                bg: 'transparent',
                color: colors.textNuanceLight,
            },
            hover: {
                color: colors.primary,
            },
        },
        transparentSecondary: {
            default: {
                bg: 'transparent',
                color: colors.secondaryOnDark,
                border: colors.secondary,
            },
        },
        transparenLightGrey: {
            default: {
                bg: 'transparent',
                color: colors.textLight,
            },
            hover: {
                bg: colors.grey200,
            },
        },
        gradient: {
            default: {
                bg: `linear-gradient(265.05deg, #2AB2B3 56.28%, ${colors.primary} 170.62%)`,
                color: colors.white,
                borderRadius: 8,
            },
        },
        dangerous: {
            default: {
                bg: 'transparent',
                color: colors.danger,
                border: colors.dangerDivider,
            },
            hover: {
                color: colors.secondaryHover,
            },
            disabled: {
                color: colors?.grey600,
                border: colors.grey300,
            },
        },
        options: {
            default: {
                bg: colors.white,
                color: colors.textMainBlack,
                border: colors.borderMain,
                borderRadius: scale(4),
            },
            hover: {
                bg: colors.grey300,
            },
        },
        variantsCurrent: {
            default: {
                bg: colors.white,
                color: colors.primary,
                border: colors.primary,
            },
            hover: {
                bg: colors.grey200,
            },
        },
        variants: {
            default: {
                bg: '#F7F9FA',
                color: colors.textSecondGrey,
                border: '#E9EFF2',
            },
            hover: {
                bg: colors.grey200,
            },
        },
        discountGreen: {
            default: {
                bg: colors.white,
                color: '#7EA67B',
                border: '#7EA67B',
                borderRadius: scale(1, true),
            },
        },
        discountViolet: {
            default: {
                bg: colors.white,
                color: '#9569A0',
                border: '#9569A0',
                borderRadius: scale(1, true),
            },
        },
        discountRed: {
            default: {
                bg: colors.white,
                color: colors.actionRed,
                border: colors.actionRed,
                borderRadius: scale(1, true),
            },
        },
        discountYellow: {
            default: {
                bg: colors.white,
                color: colors.actionBrown,
                border: colors.actionBrown,
                borderRadius: scale(1, true),
            },
        },
        turquoiseLink: {
            default: {
                bg: colors.secondaryOnDark,
                color: colors.white,
                borderRadius: 6,
            },
            hover: {
                bg: colors.primary,
            },
        },
        whiteLink: {
            default: {
                bg: colors.white,
                color: colors.secondaryOnDark,
                border: colors.borderMain,
                borderRadius: 6,
            },
        },
    },
};
