export const phoneFormat = (tel: string) => {
    if (tel.length === 11) {
        if (tel[0] === '7' || tel[0] === '8') {
            return '+7' + tel.substr(1);
        }
    }
    if (tel.length === 10) {
        return '+7' + tel;
    }

    return tel;
};

export const formatPhoneView = (tel?: string) => {
    if (!tel) return null;

    if (tel.slice(0, 2) === '+8') {
        return (
            '+' +
            '7' +
            ' ' +
            tel[1] +
            tel[2] +
            tel[3] +
            ' ' +
            tel[4] +
            tel[5] +
            tel[6] +
            ' ' +
            tel[7] +
            tel[8] +
            ' ' +
            tel[9] +
            tel[10]
        );
    }

    if ((tel.length === 11 && tel[0] === '7') || tel[0] === '8')
        return (
            '+' +
            '7' +
            ' ' +
            tel[1] +
            tel[2] +
            tel[3] +
            ' ' +
            tel[4] +
            tel[5] +
            tel[6] +
            ' ' +
            tel[7] +
            tel[8] +
            ' ' +
            tel[9] +
            tel[10]
        );

    return (
        tel[0] +
        tel[1] +
        ' ' +
        tel[2] +
        tel[3] +
        tel[4] +
        ' ' +
        tel[5] +
        tel[6] +
        tel[7] +
        ' ' +
        tel[8] +
        tel[9] +
        ' ' +
        tel[10] +
        tel[11]
    );
};
